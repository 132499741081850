import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../hooks/useMediaQuery";

const ImageComparison = ({
  beforeSrc,
  afterSrc,
  title,
  description,
  height,
}) => {
  const { t } = useTranslation();
  const [sliderPosition, setSliderPosition] = useState(50);
  const sliderRef = useRef(null);

  const handleMouseMove = (event) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      setSliderPosition((x / rect.width) * 100);
    }
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    handleMouseMove(event.touches[0]);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("touchmove", handleTouchMove);
    }
    return () => {
      if (slider) {
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  const beforeOpacity = Math.min(1, sliderPosition / 50);
  const afterOpacity = Math.min(1, (100 - sliderPosition) / 50);

  return (
    <div className="flex flex-col">
      <div
        className={`relative w-full ${height} rounded-xl overflow-hidden`}
        ref={sliderRef}
      >
        <img
          alt="Before"
          src={beforeSrc}
          className="absolute top-0 left-0 w-full h-full object-cover"
          loading="lazy"
        />
        <div
          className="absolute top-0 left-0 w-full h-full overflow-hidden"
          style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }}
        >
          <img
            alt="After"
            src={afterSrc}
            className="absolute top-0 left-0 w-full h-full object-cover"
            loading="lazy"
          />
        </div>
        <div
          className="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize"
          style={{ left: `${sliderPosition}%` }}
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 bg-white rounded-full flex items-center justify-center">
            <svg
              className="w-4 h-4 text-indigo-700"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
              <path d="M15.41 16.59L10.83 12 15.41 7.41 14 6l-6 6 6 6 1.41-1.41z" />
            </svg>
          </div>
        </div>
        <div
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-indigo-700 px-2 sm:px-4 py-1 sm:py-2 rounded text-sm sm:text-lg font-semibold transition-opacity duration-200"
          style={{ opacity: beforeOpacity }}
        >
          {t("sectionImageExamples.slider.before")}
        </div>
        <div
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-indigo-700 px-2 sm:px-4 py-1 sm:py-2 rounded text-sm sm:text-lg font-semibold transition-opacity duration-200"
          style={{ opacity: afterOpacity }}
        >
          {t("sectionImageExamples.slider.after")}
        </div>
      </div>
      <h3 className="mt-4 text-xl font-semibold text-indigo-700">{t(title)}</h3>
      <p className="mt-2 text-sm text-gray-600">{t(description)}</p>
    </div>
  );
};

const SectionImageExamples = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery("(min-width: 769px)");

  return (
    <section
      id="examples"
      className="bg-gradient-to-b from-indigo-50 to-white py-16 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-7xl mx-auto">
        <p className="text-base font-semibold leading-7 text-indigo-600 text-center">
          {t("sectionImageExamples.subtitle")}
        </p>
        <h2 className="text-3xl font-bold text-center text-indigo-700 mb-12">
          {t("sectionImageExamples.title")}
        </h2>
        <div className="space-y-12">
          <div className="w-full">
            <ImageComparison
              beforeSrc="/images/before/interior_original.jpg"
              afterSrc="/images/after/interior_after.png"
              title="sectionImageExamples.interiorEnhancement.title"
              description="sectionImageExamples.interiorEnhancement.description"
              height={isDesktop ? "h-[700px]" : "h-[500px]"}
            />
          </div>
          <div
            className={`grid ${
              isDesktop ? "grid-cols-2" : "grid-cols-1"
            } gap-8`}
          >
            <ImageComparison
              beforeSrc="/images/before/exterior_original.jpg"
              afterSrc="/images/after/exterior_after.png"
              title="sectionImageExamples.exteriorImprovement.title"
              description="sectionImageExamples.exteriorImprovement.description"
              height={isDesktop ? "h-[600px]" : "h-[500px]"}
            />
            <ImageComparison
              beforeSrc="/images/before/dark_pool_original.jpeg"
              afterSrc="/images/after/dark_pool_after.png"
              title="sectionImageExamples.nightImageImprovement.title"
              description="sectionImageExamples.nightImageImprovement.description"
              height={isDesktop ? "h-[600px]" : "h-[500px]"}
            />
          </div>
          <div className="w-full">
            <ImageComparison
              beforeSrc="/images/before/blurry_original.jpg"
              afterSrc="/images/after/blurry_after.png"
              title="sectionImageExamples.blurryImageCorrection.title"
              description="sectionImageExamples.blurryImageCorrection.description"
              height={isDesktop ? "h-[700px]" : "h-[500px]"}
            />
          </div>
          <div className="w-full">
            <ImageComparison
              beforeSrc="/images/before/landscape_original.jpg"
              afterSrc="/images/after/landscape_after.png"
              title="sectionImageExamples.natureLandscapes.title"
              description="sectionImageExamples.natureLandscapes.description"
              height={isDesktop ? "h-[700px]" : "h-[500px]"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionImageExamples;
