import React, { useState, useRef, useEffect } from "react";

const ImageComparison = ({
  beforeSrc,
  afterSrc,
  title,
  description,
  height,
}) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const sliderRef = useRef(null);

  const handleMouseMove = (event) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      setSliderPosition((x / rect.width) * 100);
    }
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    handleMouseMove(event.touches[0]);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("touchmove", handleTouchMove);
    }
    return () => {
      if (slider) {
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  return (
    <div className="flex flex-col">
      <div
        className={`relative w-full ${height} rounded-xl overflow-hidden`}
        ref={sliderRef}
      >
        <img
          alt="Before"
          src={beforeSrc}
          className="absolute top-0 left-0 w-full h-full object-cover"
          loading="lazy"
        />
        <div
          className="absolute top-0 left-0 w-full h-full overflow-hidden"
          style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
        >
          <img
            alt="After"
            src={afterSrc}
            className="absolute top-0 left-0 w-full h-full object-cover"
            loading="lazy"
          />
        </div>
        <div
          className="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize"
          style={{ left: `${sliderPosition}%` }}
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 bg-white rounded-full flex items-center justify-center">
            <svg
              className="w-4 h-4 text-indigo-700"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
              <path d="M15.41 16.59L10.83 12 15.41 7.41 14 6l-6 6 6 6 1.41-1.41z" />
            </svg>
          </div>
        </div>
        <div className="absolute bottom-4 left-4 bg-white text-indigo-700 px-2 py-1 rounded text-sm">
          Before
        </div>
        <div className="absolute bottom-4 right-4 bg-white text-indigo-700 px-2 py-1 rounded text-sm">
          After
        </div>
      </div>
      <h3 className="mt-4 text-xl font-semibold text-indigo-700">{title}</h3>
      <p className="mt-2 text-sm text-gray-600">{description}</p>
    </div>
  );
};

const Img2Img = () => {
  const [imagePairs, setImagePairs] = useState([]);

  useEffect(() => {
    // In a real scenario, you'd fetch this list from your server or file system
    // For this example, we'll use a hardcoded list of image pairs
    const testImagePairs = [
      {
        before: "2_v1.png",
        after: "2_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "3_v1.png",
        after: "3_v2.png",
        title: "Telmo Image 2",
      },
      // Add more image pairs as needed
      {
        before: "4_v1.png",
        after: "4_v2.png",
        title: "Another Test Image",
      },
      {
        before: "5_v1.png",
        after: "5_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "6_v1.png",
        after: "6_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "7_v1.png",
        after: "7_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "8_v1.png",
        after: "8_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "9_v1.png",
        after: "9_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "10_v1.png",
        after: "10_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "11_v1.jpg",
        after: "11_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "12_v1.jpg",
        after: "12_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "13_v1.jpg",
        after: "13_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "14_v1.jpg",
        after: "14_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "15_v1.jpg",
        after: "15_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "16_v1.jpg",
        after: "16_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "17_v1.jpg",
        after: "17_v2.png",
        title: "Telmo Image 2",
      },
      {
        before: "18_v1.jpg",
        after: "18_v2.png",
        title: "Telmo Image 2",
      },
    ];
    setImagePairs(testImagePairs);
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center text-indigo-700 mb-8">
        Image to Image Test
      </h1>
      <div className="space-y-12">
        {imagePairs.map((pair, index) => (
          <div key={index} className="w-full">
            <ImageComparison
              beforeSrc={`/images/tests/v1/${pair.before}`}
              afterSrc={`/images/tests/v2/${pair.after}`}
              title={pair.title || `Test Image ${index + 1}`}
              description="This is a test image for the Img2Img component."
              height="h-[500px]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Img2Img;
