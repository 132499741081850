export default function TermsAndServices() {
  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Terms of Use for HomeShotPro
        </h1>
        <p className="text-gray-600 mb-8">Last Updated: August 25, 2024</p>
        <div className="prose prose-lg text-gray-700">
          <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introduction</h2>
          <p>
            Welcome to HomeShotPro ("we," "us," or "our"), a service operated by
            Cristian Bello, registered in Portugal. These Terms of Use govern
            your access to and use of our website at www.homeshotpro.com and all
            related services (collectively, the "Service"). By using our
            Service, you agree to comply with and be bound by these Terms of
            Use.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            2. Service Description
          </h2>
          <p>
            HomeShotPro provides AI-enhanced image services for real estate and
            short-term rental property owners. Users can upload images of their
            listings, which our AI technology will then improve. Enhanced photos
            will be delivered within hours of placing an order.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">3. User Accounts</h2>
          <p>
            3.1. To use our Service, you may need to create an account. You are
            responsible for maintaining the confidentiality of your account
            information and for all activities that occur under your account.
          </p>
          <p>3.2. You must be at least 18 years old to use the Service.</p>
          <p>
            3.3. You agree to provide accurate, current, and complete
            information during the registration process and to update such
            information to keep it accurate, current, and complete.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">4. User Content</h2>
          <p>
            4.1. "User Content" refers to any images, photographs, or other
            content you upload to our Service for enhancement.
          </p>
          <p>
            4.2. You retain all ownership rights to your User Content. However,
            by uploading User Content, you grant us a worldwide, non-exclusive,
            royalty-free license to use, reproduce, modify, and display your
            User Content for the purpose of providing and improving our Service.
          </p>
          <p>
            4.3. You are solely responsible for your User Content and the
            consequences of uploading it. You represent and warrant that:
          </p>
          <p>
            4.4. By uploading User Content, you explicitly agree to allow
            AI-based modifications to your images as part of our Service.
          </p>
          <ul>
            <li>
              a) You own or have the necessary rights to use and authorize us to
              use your User Content;
            </li>
            <li>
              b) Your User Content does not violate any third party's
              intellectual property rights, privacy rights, or other legal
              rights;
            </li>
            <li>
              c) Your User Content complies with these Terms of Use and all
              applicable laws and regulations.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            5. AI-Enhanced Images
          </h2>
          <p>
            5.1. The AI-enhanced images we provide ("Enhanced Images") are based
            on your User Content but may differ significantly from the original
            images.
          </p>
          <p>
            5.2. We grant you a non-exclusive, worldwide license to use,
            reproduce, and display the Enhanced Images for any purpose,
            commercial or non-commercial.
          </p>
          <p>
            5.3. While we strive for high quality, we do not guarantee that
            Enhanced Images will be error-free or meet your specific
            requirements.
          </p>
          <p>
            5.4. You are responsible for ensuring that the Enhanced Images
            accurately represent the property and comply with all applicable
            local, state, and national real estate advertising laws and
            regulations. HomeShotPro is not responsible for any
            misrepresentation claims arising from the use of Enhanced Images.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            6. Fees and Payment
          </h2>
          <p>
            6.1. We reserve the right to charge fees for the use of our Service.
            You agree to pay all fees and charges associated with your account
            on the terms and using the method you choose at the time of
            purchase.
          </p>
          <p>
            6.2. All fees are non-refundable except as expressly set forth in
            our refund policy, which can be found at homeproshot.com/refund, or
            as required by law.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            7. Prohibited Conduct
          </h2>
          <p>You agree not to:</p>
          <ul>
            <li>
              a) Use the Service for any illegal purpose or in violation of any
              local, state, national, or international law;
            </li>
            <li>
              b) Violate or encourage others to violate any right of a third
              party, including intellectual property rights;
            </li>
            <li>
              c) Upload, post, or transmit any User Content that is unlawful,
              harmful, threatening, abusive, harassing, defamatory, vulgar,
              obscene, or otherwise objectionable;
            </li>
            <li>
              d) Attempt to gain unauthorized access to the Service or to other
              users' accounts;
            </li>
            <li>
              e) Use the Service in any manner that could interfere with,
              disrupt, negatively affect, or inhibit other users from fully
              enjoying the Service.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            8. Intellectual Property Rights
          </h2>
          <p>
            8.1. The Service and its original content (excluding User Content),
            features, and functionality are and will remain the exclusive
            property of HomeShotPro and its licensors.
          </p>
          <p>
            8.2. Our trademarks and trade dress may not be used in connection
            with any product or service without our prior written consent.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">9. Termination</h2>
          <p>
            We may terminate or suspend your account and access to the Service
            immediately, without prior notice or liability, for any reason,
            including if you breach these Terms of Use.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            10. Disclaimer of Warranties
          </h2>
          <p>
            THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
            WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            11. Limitation of Liability
          </h2>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
            HOMESHOTPRO BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR
            USE OF THE SERVICE.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            12. Governing Law and Jurisdiction
          </h2>
          <p>
            These Terms of Use shall be governed by and construed in accordance
            with the laws of Portugal. Any dispute arising under these Terms
            shall be subject to the exclusive jurisdiction of the courts of
            Portugal.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            13. Changes to Terms of Use
          </h2>
          <p>
            We reserve the right to modify or replace these Terms of Use at any
            time. If a revision is material, we will provide at least 30 days'
            notice prior to any new terms taking effect.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">14. Contact Us</h2>
          <p>
            If you have any questions about these Terms of Use, please contact
            us at hello@homeshotpro.com.
          </p>

          <p className="mt-8">
            By using HomeShotPro, you acknowledge that you have read,
            understood, and agree to be bound by these Terms of Use.
          </p>
          <h2 className="text-2xl font-semibold mt-8 mb-4">
            15. Indemnification
          </h2>
          <p>
            You agree to indemnify, defend, and hold harmless HomeShotPro, its
            officers, directors, employees, agents, and affiliates from and
            against any and all claims, damages, obligations, losses,
            liabilities, costs, and expenses (including but not limited to
            attorney's fees) arising from: (i) your use of the Service; (ii)
            your violation of these Terms of Use; (iii) your violation of any
            third party right, including without limitation any copyright,
            property, publicity, or privacy right; or (iv) any claim that your
            User Content caused damage to a third party.
          </p>
        </div>
      </div>
    </div>
  );
}
