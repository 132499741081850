import React, { useEffect } from "react";

const GoogleAnalytics = ({ measurementId }) => {
  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Load the Google Tag Manager script
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.async = true;
    document.head.appendChild(script);

    // Once the script is loaded, configure Google Analytics
    script.onload = () => {
      gtag("js", new Date());
      gtag("config", measurementId);
    };

    // Expose gtag to window in case it's needed elsewhere
    window.gtag = gtag;

    // Cleanup
    return () => {
      document.head.removeChild(script);
    };
  }, [measurementId]);

  return null;
};

export default GoogleAnalytics;
