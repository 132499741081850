import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../api/supabaseClient";
import { useParams } from "react-router-dom";
import { FiUpload, FiX, FiCheck } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/heic",
];

const ImageUpload = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [order, setOrder] = useState(null);
  const [remainingUploads, setRemainingUploads] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { uploadLink } = useParams();

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (files.length > 0) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [files]);

  const fetchOrder = async () => {
    try {
      const { data, error } = await supabase
        .from("orders")
        .select("*")
        .eq("upload_link", uploadLink)
        .single();

      if (error) throw error;

      setOrder(data);
      setRemainingUploads(
        Math.max(0, data.images_paid - (data.images_uploaded || 0))
      );
    } catch (error) {
      console.error("Error fetching order:", error);
      toast.error(t("imageUpload.toasts.invalidUploadLink"));
    }
  };

  const validateFile = (file) => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return t("imageUpload.toasts.invalidFileType", { fileName: file.name });
    }
    if (file.size > MAX_FILE_SIZE) {
      return t("imageUpload.toasts.fileTooLarge", {
        fileName: file.name,
        maxSize: MAX_FILE_SIZE / 1024 / 1024,
      });
    }
    return null;
  };

  const handleFileChange = useCallback(
    (event) => {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = [];
      const errors = [];

      selectedFiles.forEach((file) => {
        const error = validateFile(file);
        if (error) {
          errors.push(error);
        } else {
          validFiles.push(file);
        }
      });

      if (validFiles.length + files.length > remainingUploads) {
        errors.push(
          t("imageUpload.toasts.tooManyFiles", { count: remainingUploads })
        );
        validFiles.splice(remainingUploads - files.length);
      }

      setFiles((prevFiles) => [...prevFiles, ...validFiles]);

      if (errors.length > 0) {
        errors.forEach((error) => toast.error(error));
      }
    },
    [remainingUploads, files.length, t]
  );

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (!order) return;
    setIsUploading(true);

    for (const file of files) {
      try {
        const error = validateFile(file);
        if (error) {
          toast.error(error);
          continue;
        }

        const filename = `${Date.now()}_${file.name}`;
        const { data, error: uploadError } = await supabase.storage
          .from("images")
          .upload(`input/${order.id}/${filename}`, file, {
            cacheControl: "3600",
            upsert: false,
          });

        if (uploadError) throw uploadError;

        const { error: insertError } = await supabase.from("images").insert({
          order_id: order.id,
          user_id: order.user_id,
          input_image: data.path,
          image_status: "pending",
        });

        if (insertError) throw insertError;
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error(
          t("imageUpload.toasts.uploadError", { fileName: file.name })
        );
      }
    }

    try {
      const newImagesUploaded = (order.images_uploaded || 0) + files.length;

      const { error } = await supabase
        .from("orders")
        .update({ status: "uploaded", images_uploaded: newImagesUploaded })
        .eq("id", order.id);

      if (error) throw error;

      setRemainingUploads(remainingUploads - files.length);
      setFiles([]);

      setIsUploading(false);
      setShowModal(true);
    } catch (error) {
      setIsUploading(false);
      console.error("Error updating order status:", error);
      toast.error(t("imageUpload.toasts.updateStatusError"));
    }
  };

  const Modal = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full mx-4">
        <h2 className="text-2xl font-bold mb-4">
          {t("imageUpload.modal.title")}
        </h2>
        <p className="mb-4">{t("imageUpload.modal.description")}</p>
        {remainingUploads > 0 && (
          <p className="mb-4">
            {t("imageUpload.modal.remainingUploads", {
              count: remainingUploads,
            })}
          </p>
        )}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md transition duration-300 ease-in-out"
          >
            {t("imageUpload.modal.closeButton")}
          </button>
        </div>
      </div>
    </div>
  );

  if (!order)
    return <div className="text-center p-4">{t("imageUpload.loading")}</div>;

  return (
    <div className="max-w-3xl mx-auto p-4 md:p-8 bg-white shadow-lg rounded-lg">
      <ToastContainer position="top-right" autoClose={5000} />
      <h1 className="text-2xl md:text-4xl font-bold mb-6 text-center text-gray-800">
        {t("imageUpload.title")}
      </h1>
      <p className="text-base md:text-lg text-gray-600 mb-8 text-center">
        {t("imageUpload.description")}
      </p>

      <div className="mb-8 bg-blue-50 border-l-4 border-blue-500 p-4 rounded-md">
        <h2 className="text-lg md:text-xl font-semibold mb-3 text-blue-800">
          {t("imageUpload.remainingUploads", { count: remainingUploads })}
        </h2>
        {/* <ul className="space-y-2">
          {t("imageUpload.features", { returnObjects: true }).map(
            (feature, index) => (
              <li key={index} className="flex items-start">
                <FiCheck className="flex-shrink-0 w-5 h-5 text-green-500 mt-1 mr-2" />
                <span>{feature}</span>
              </li>
            )
          )}
        </ul> */}
      </div>

      <div className="space-y-6">
        <div>
          <label
            htmlFor="file-upload"
            className="cursor-pointer bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-md inline-flex items-center justify-center transition duration-300 ease-in-out w-full"
          >
            <FiUpload className="mr-2" />
            {t("imageUpload.selectPhotos", { count: remainingUploads })}
          </label>
          <input
            id="file-upload"
            type="file"
            multiple
            accept={ALLOWED_FILE_TYPES.join(",")}
            onChange={handleFileChange}
            disabled={remainingUploads === 0}
            className="hidden"
          />
        </div>

        {files.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-2">
              {t("imageUpload.selectedPhotos")}
            </h3>
            <ul className="bg-gray-50 rounded-md p-3 space-y-2">
              {files.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center py-2 px-3 bg-white rounded-md shadow-sm"
                >
                  <span className="truncate max-w-xs">
                    {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                  </span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="text-red-500 hover:text-red-700 transition duration-300 ease-in-out"
                  >
                    <FiX />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <button
          onClick={handleUpload}
          disabled={files.length === 0 || remainingUploads === 0 || isUploading}
          className="w-full bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-md transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isUploading ? (
            <>
              <span className="animate-spin inline-block mr-2">&#9696;</span>
              {t("imageUpload.uploading")}
            </>
          ) : (
            t("imageUpload.transformButton")
          )}
        </button>
      </div>

      <p className="mt-6 text-sm text-gray-500 text-center">
        {t("imageUpload.termsNotice.beforeTerms")}
        <a
          href="/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {t("imageUpload.termsNotice.termsLink")}
        </a>
        {t("imageUpload.termsNotice.betweenTermsAndPrivacy")}
        <a
          href="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {t("imageUpload.termsNotice.privacyLink")}
        </a>
        {t("imageUpload.termsNotice.afterPrivacy")}
      </p>

      {showModal && <Modal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default ImageUpload;
