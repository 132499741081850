import React from "react";
import { useTranslation } from "react-i18next";
import { FiTrendingUp, FiClock, FiDollarSign } from "react-icons/fi";
import { FaQuoteLeft } from "react-icons/fa";

const BenefitItem = ({ icon, stat, text }) => (
  <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg">
    <div className="text-blue-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold text-gray-800 mb-2">{stat}</h3>
    <p className="text-gray-600">{text}</p>
  </div>
);

const SectionWhyUs = () => {
  const { t } = useTranslation();

  const benefits = t("sectionWhyUs.benefits", { returnObjects: true });

  return (
    <section className="bg-gradient-to-b from-blue-50 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-blue-900 mb-4">
            {t("sectionWhyUs.title")}
          </h2>
          <p className="text-center text-gray-700 mb-12 max-w-3xl mx-auto">
            {t("sectionWhyUs.subtitle")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <BenefitItem
            icon={<FiTrendingUp className="w-12 h-12" />}
            stat={benefits[0].stat}
            text={benefits[0].text}
          />
          <BenefitItem
            icon={<FiClock className="w-12 h-12" />}
            stat={benefits[1].stat}
            text={benefits[1].text}
          />
          <BenefitItem
            icon={<FiDollarSign className="w-12 h-12" />}
            stat={benefits[2].stat}
            text={benefits[2].text}
          />
        </div>

        <div className="text-center mb-16">
          <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-6">
            {t("sectionWhyUs.tryItOut")}
          </p>
          <a
            href="#pricing"
            className="inline-flex rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-6 py-3 text-sm font-semibold text-white shadow-md hover:from-blue-600 hover:to-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 transition-all duration-300"
          >
            {t("sectionWhyUs.cta")}
          </a>
          <p className="text-xs text-gray-500 mt-4 italic">
            {t("sectionWhyUs.benefitsSource")}
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-8 max-w-3xl mx-auto">
          <div className="flex items-start mb-4">
            <FaQuoteLeft className="text-blue-500 text-4xl mr-4 flex-shrink-0" />
            <p className="text-lg text-gray-700 italic">
              "{t("sectionWhyUs.testimonial.quote")}"
            </p>
          </div>
          <div className="flex items-center justify-end">
            <div className="text-right">
              <p className="text-base font-semibold text-gray-900">
                {t("sectionWhyUs.testimonial.author")}
              </p>
              <div className="flex items-center mt-1">
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    className="w-4 h-4 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWhyUs;
