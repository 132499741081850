import React, { useState } from "react";
import { supabase } from "../api/supabaseClient";
import { FiUpload, FiX, FiCheck } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/heic",
];
const MAX_FREE_UPLOADS = 5;

const FreePackageUpload = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isCheckingEligibility, setIsCheckingEligibility] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successData, setSuccessData] = useState({
    orderId: null,
    uploadLink: null,
  });

  const validateFile = (file) => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return t("freePackageUpload.toasts.invalidFileType", {
        fileName: file.name,
      });
    }
    if (file.size > MAX_FILE_SIZE) {
      return t("freePackageUpload.toasts.fileTooLarge", {
        fileName: file.name,
        maxSize: MAX_FILE_SIZE / 1024 / 1024,
      });
    }
    return null;
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    const errors = [];

    selectedFiles.forEach((file) => {
      const error = validateFile(file);
      if (error) {
        errors.push(`${file.name}: ${error}`);
      } else if (validFiles.length < MAX_FREE_UPLOADS) {
        validFiles.push(file);
      }
    });

    setFiles((prevFiles) => {
      const newFiles = [...prevFiles, ...validFiles].slice(0, MAX_FREE_UPLOADS);
      if (newFiles.length > MAX_FREE_UPLOADS) {
        errors.push(
          t("freePackageUpload.toasts.maxUploadsReached", {
            max: MAX_FREE_UPLOADS,
          })
        );
      }
      return newFiles;
    });

    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const checkEligibility = async (email) => {
    setIsCheckingEligibility(true);
    try {
      const { data: userData, error: userError } = await supabase
        .from("users")
        .select("id")
        .eq("email", email)
        .single();

      if (userError) {
        if (userError.code === "PGRST116") {
          // User doesn't exist, so they're eligible
          return true;
        }
        throw userError;
      }

      const { data, error } = await supabase
        .from("orders")
        .select("id")
        .eq("order_type", "Free")
        .eq("user_id", userData.id)
        .single();

      if (error && error.code === "PGRST116") {
        // No matching row found, user is eligible
        return true;
      } else if (data) {
        // User has already used the free package
        setShowModal(true);
        return false;
      }
    } catch (error) {
      console.error("Error checking eligibility:", error);
      toast.error(t("freePackageUpload.toasts.eligibilityError"));
      return false;
    } finally {
      setIsCheckingEligibility(false);
    }
  };

  const handleUpload = async () => {
    if (!email || files.length === 0) {
      toast.error(t("freePackageUpload.toasts.missingInfo"));
      return;
    }

    const isEligible = await checkEligibility(email);
    if (!isEligible) return;

    setIsUploading(true);

    try {
      // Generate a unique upload link for the free package
      const uniqueUploadLink = `free-package-${Date.now()}`;

      // Create or get user
      const { data: userData, error: userError } = await supabase
        .from("users")
        .upsert(
          { email: email },
          { onConflict: "email", ignoreDuplicates: false }
        )
        .select()
        .single();

      if (userError) throw userError;

      // Create free order
      const { data: orderData, error: orderError } = await supabase
        .from("orders")
        .insert({
          user_id: userData.id,
          order_type: "Free",
          amount: 0,
          status: "uploaded",
          images_paid: MAX_FREE_UPLOADS,
          upload_link: uniqueUploadLink,
        })
        .select()
        .single();

      if (orderError) throw orderError;

      // Upload files
      for (const file of files) {
        const filename = `${Date.now()}_${file.name}`;
        const { data, error: uploadError } = await supabase.storage
          .from("images")
          .upload(`input/${orderData.id}/${filename}`, file);

        if (uploadError) throw uploadError;

        await supabase.from("images").insert({
          order_id: orderData.id,
          user_id: userData.id,
          input_image: data.path,
          image_status: "pending",
        });
      }
      setFiles([]);
      setEmail("");

      // Show the success modal
      setSuccessData({ orderId: orderData.id, uploadLink: uniqueUploadLink });
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error(t("freePackageUpload.toasts.uploadError"));
    } finally {
      setIsUploading(false);
    }
  };

  const SuccessModal = ({ onClose, orderId, uploadLink }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-6 md:p-8 rounded-lg max-w-md w-full">
        <h2 className="text-xl md:text-2xl font-bold mb-4">
          {t("freePackageUpload.successModal.title")}
        </h2>
        <p className="mb-6 text-gray-600">
          {t("freePackageUpload.successModal.message")}
        </p>
        <div className="flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300 w-full md:w-auto"
          >
            {t("freePackageUpload.successModal.close")}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="max-w-3xl mx-auto p-4 md:p-8 bg-white shadow-lg rounded-lg">
      <ToastContainer position="top-right" autoClose={5000} />
      <h1 className="text-2xl md:text-4xl font-bold mb-6 text-center text-gray-800">
        {t("freePackageUpload.title")}
      </h1>
      <p className="text-base md:text-lg text-gray-600 mb-8 text-center">
        {t("freePackageUpload.subtitle")}
      </p>

      <div className="mb-8 bg-blue-50 border-l-4 border-blue-500 p-4 rounded-md">
        <h2 className="text-lg md:text-xl font-semibold mb-3 text-blue-800">
          {t("freePackageUpload.benefitsTitle")}
        </h2>
        <ul className="space-y-2">
          {(Array.isArray(
            t("freePackageUpload.benefits", { returnObjects: true })
          )
            ? t("freePackageUpload.benefits", { returnObjects: true })
            : [t("freePackageUpload.benefits")]
          ).map((benefit, index) => (
            <li key={index} className="flex items-start">
              <FiCheck className="flex-shrink-0 w-5 h-5 text-green-500 mt-1 mr-2" />
              <span dangerouslySetInnerHTML={{ __html: benefit }} />
            </li>
          ))}
        </ul>
        <p className="text-xs text-gray-500 mt-2 italic">
          {t("freePackageUpload.benefitsSource")}
        </p>
      </div>

      <div className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            {t("freePackageUpload.emailLabel")}
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
            placeholder={t("freePackageUpload.emailPlaceholder")}
          />
        </div>

        <div>
          <label
            htmlFor="file-upload"
            className="cursor-pointer bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-md inline-flex items-center justify-center transition duration-300 ease-in-out w-full"
          >
            <FiUpload className="mr-2" />
            {t("freePackageUpload.selectPhotos", { max: MAX_FREE_UPLOADS })}
          </label>
          <input
            id="file-upload"
            type="file"
            multiple
            accept={ALLOWED_FILE_TYPES.join(",")}
            onChange={handleFileChange}
            className="hidden"
          />
        </div>

        {files.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold mb-2">
              {t("freePackageUpload.selectedPhotos")}
            </h3>
            <ul className="bg-gray-50 rounded-md p-3 space-y-2">
              {files.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center py-2 px-3 bg-white rounded-md shadow-sm"
                >
                  <span className="truncate max-w-xs">
                    {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                  </span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="text-red-500 hover:text-red-700 transition duration-300 ease-in-out"
                  >
                    <FiX />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <button
          onClick={handleUpload}
          disabled={
            isUploading || isCheckingEligibility || files.length === 0 || !email
          }
          className="w-full bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-md transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isUploading
            ? t("freePackageUpload.uploading")
            : isCheckingEligibility
            ? t("freePackageUpload.checkingEligibility")
            : t("freePackageUpload.transformPhotos")}
        </button>
      </div>

      <p className="mt-6 text-sm text-gray-500 text-center">
        {t("freePackageUpload.termsAgreement.beforeTerms")}
        <a
          href="/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {t("freePackageUpload.termsOfService")}
        </a>
        {t("freePackageUpload.termsAgreement.betweenTermsAndPrivacy")}
        <a
          href="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          {t("freePackageUpload.privacyPolicy")}
        </a>
        {t("freePackageUpload.termsAgreement.afterPrivacy")}
      </p>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white p-6 md:p-8 rounded-lg max-w-md w-full">
            <h2 className="text-xl md:text-2xl font-bold mb-4">
              {t("freePackageUpload.modal.title")}
            </h2>
            <p className="mb-6 text-gray-600">
              {t("freePackageUpload.modal.message")}
            </p>
            <div className="flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300 w-full md:w-auto"
              >
                {t("freePackageUpload.modal.close")}
              </button>
              <button
                onClick={() => (window.location.href = "/#pricing")}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300 w-full md:w-auto"
              >
                {t("freePackageUpload.modal.viewPaidOptions")}
              </button>
            </div>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <SuccessModal
          onClose={() => setShowSuccessModal(false)}
          orderId={successData.orderId}
          uploadLink={successData.uploadLink}
        />
      )}
    </div>
  );
};

export default FreePackageUpload;
