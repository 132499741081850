import { useTranslation } from "react-i18next";

const navigation = {
  company: [
    // { name: "footer.company.forHomeowners", href: "/home-photos" },
    // { name: "footer.company.forRealEstate", href: "/real-estate-photos" },
    { name: "footer.support.faq", href: "#faq" },
    { name: "footer.support.contactUs", href: "mailto:hello@homeshotpro.com" },
  ],
  legal: [
    // { name: "footer.legal.refund", href: "/refund" },
    { name: "footer.legal.privacyPolicy", href: "/privacy" },
    { name: "footer.legal.termsOfService", href: "/terms" },
  ],
};

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer aria-labelledby="footer-heading" className="bg-gray-900">
      <h2 id="footer-heading" className="sr-only">
        {t("footer.heading")}
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-4 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img
              alt={t("footer.logoAlt")}
              src="/images/logo_dark.png"
              className="h-16"
            />
            <p className="text-sm leading-6 text-gray-300">
              {t("footer.description")}
            </p>
          </div>
          <div className="mt-16 grid grid-cols-3 gap-8 xl:col-span-3 xl:mt-0">
            {Object.keys(navigation).map((category) => (
              <div key={category}>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  {t(`footer.${category}.title`)}
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation[category].map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {t(item.name)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">
            {t("footer.copyright", { year: new Date().getFullYear() })}
          </p>
        </div>
      </div>
    </footer>
  );
}
