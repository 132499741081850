import React from "react";
import SectionHero from "./HomeHero";
import SectionSteps from "./HomeSteps";
import SectionImageExamples from "./HomeImageExamples";
import SectionWhyUs from "./HomeWhyUs";
import SectionPricing from "./HomePricing";
import SectionFAQ from "./HomeFAQ";
import SectionCTA from "./HomeCTA";

function Home() {
  return (
    <>
      <div>
        <SectionHero />
        <SectionSteps />
        <SectionImageExamples />
        <SectionWhyUs />
        <SectionPricing />
        <SectionFAQ />
        <SectionCTA />
      </div>
    </>
  );
}

export default Home;
