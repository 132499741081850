export default function RefundPolicy() {
  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
          The 'Listing-Ready' Money Back Guarantee
        </h1>
        <div className="prose prose-lg text-gray-700">
          <p>
            At HomeShotPro, we're confident in our AI-enhanced images for your
            real estate and vacation rental listings. However, we understand
            that not every enhancement may meet your expectations.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            Refund Eligibility
          </h2>
          <ul>
            <li>
              You can request a refund within 7 days of receiving your enhanced
              images.
            </li>
            <li>
              Refunds are available for packages of 20, 50, or 100 images.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Full Refund</h2>
          <p>
            If you're not satisfied with the majority of your enhanced images,
            you may be eligible for a full refund. To qualify:
          </p>
          <ul>
            <li>
              You must not have used any of the enhanced images in your
              listings.
            </li>
            <li>You must request the refund within the 7-day period.</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Partial Refund</h2>
          <p>
            We also offer partial refunds based on the number of unsatisfactory
            images:
          </p>
          <ul>
            <li>
              For every 10% of images you're not satisfied with, you're eligible
              for a 10% refund.
            </li>
            <li>
              For example, if you purchased a 50-image package and are
              unsatisfied with 10 images, you'd be eligible for a 20% refund.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            How to Request a Refund
          </h2>
          <ol>
            <li>
              Email{" "}
              <a href="mailto:help@homeshotpro.com">help@homeshotpro.com</a>{" "}
              with the subject line "Refund Request - [Your Order Number]"
            </li>
            <li>
              In the email, please specify:
              <ul>
                <li>The number of images you're unsatisfied with</li>
                <li>Brief reasons for your dissatisfaction</li>
                <li>Whether you're requesting a full or partial refund</li>
              </ul>
            </li>
          </ol>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            Refund Processing
          </h2>
          <ul>
            <li>
              We aim to process all refund requests within 5 business days.
            </li>
            <li>
              Please note that depending on your bank, it may take additional
              time for the refunded amount to appear in your account.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Our Commitment</h2>
          <p>
            We're dedicated to providing high-quality, listing-ready images. If
            you're not satisfied, we encourage you to reach out to our support
            team at{" "}
            <a href="mailto:help@homeshotpro.com">help@homeshotpro.com</a>.
            We're always happy to work with you to ensure you get the best
            possible results for your listings.
          </p>

          <p className="mt-8">
            By purchasing our services, you agree to these refund terms. We
            reserve the right to modify this policy at any time, so please
            review it frequently.
          </p>
        </div>
      </div>
    </div>
  );
}
