import React from "react";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import useMediaQuery from "../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";

const SectionFAQ = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 640px)");

  const faqCount = 8; // Update this number if you add or remove FAQs

  const faqs = Array.from({ length: faqCount }, (_, i) => ({
    question: t(`sectionFAQ.faq${i + 1}.question`),
    answer: t(`sectionFAQ.faq${i + 1}.answer`),
    mobileAnswer: t(`sectionFAQ.faq${i + 1}.mobileAnswer`),
  }));

  return (
    <div id="faq" className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-3xl font-bold leading-10 tracking-tight text-white">
            {t("sectionFAQ.title")}
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq, index) => (
              <Disclosure as="div" key={index} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-xl font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-xl leading-7 text-gray-300">
                        {isMobile ? faq.mobileAnswer : faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default SectionFAQ;
