import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
          Privacy Policy for HomeShotPro
        </h1>
        <p className="text-lg text-gray-700 mb-8">
          Last updated: August 25, 2024
        </p>
        <div className="prose prose-lg text-gray-700">
          <p>
            At HomeShotPro, we prioritize your privacy and the protection of
            your data. All data collected, both personal and non-personal, is
            protected with the highest global standards.
          </p>
          <p>
            HomeShotPro is operated by Cristian Bello, registered in Portugal.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">1. Notice</h2>
          <p>
            We will explicitly ask for your permission whenever we request
            information that can be used to personally identify you. This
            includes when you upload images, provide payment information, or
            interact with our website in ways that require data collection.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">2. Your Consent</h2>
          <p>
            You always have the choice whether to give us permission to process
            your information. For data not essential to our core services (e.g.,
            marketing cookies), you can decline permission without affecting
            your access to HomeShotPro. If you choose not to provide essential
            information (e.g., payment details, email address), we may be unable
            to provide you with certain aspects of our service.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">3. Usage</h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            3.1. Data We Collect
          </h3>
          <p>
            We collect various forms of personally identifiable information,
            including:
          </p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Payment information</li>
            <li>Images you upload for processing</li>
          </ul>
          <p>
            We also use cookies for analytics and marketing purposes. These
            cookies help us understand how our website is used and allow us to
            provide more relevant marketing content.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            3.2. How We Use Your Data
          </h3>
          <p>We use your personally identifiable information to:</p>
          <ul>
            <li>Provide you with access to HomeShotPro's features</li>
            <li>Process your payments</li>
            <li>Inform you about important service updates</li>
            <li>
              Contact you about your order status or respond to support requests
            </li>
            <li>Send marketing emails or newsletters (if you've opted in)</li>
          </ul>
          <p>
            We use Google Analytics and other marketing tools to analyze website
            performance and improve our services. These tools only receive
            anonymized performance data, not personal information.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            4. Retention of Information
          </h2>
          <p>
            We retain your personal information as long as necessary to provide
            our services. All uploaded images are automatically deleted after 7
            days. Other personal information will be deleted within 14 days of
            account deletion, subscription expiration, or upon your explicit
            request. Information may be retained longer if required by law.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">5. Your Rights</h2>
          <p>As per GDPR, you have the following rights:</p>
          <ul>
            <li>Right of access</li>
            <li>Right to rectification</li>
            <li>Right to erasure</li>
            <li>Right to restriction of processing</li>
            <li>Right to data portability</li>
            <li>Right to object</li>
          </ul>
          <p>
            To exercise these rights, please contact us at
            hello@homeshotpro.com.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">6. Opting Out</h2>
          <p>
            You may opt out of non-essential communications, services, or cookie
            usage at any time through your account settings or by contacting us.
            Every marketing email we send will include an unsubscribe link at
            the bottom.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            7. Security of Your Information
          </h2>
          <p>We implement various measures to protect your data, including:</p>
          <ul>
            <li>Security software and virus scanners</li>
            <li>TLS secured connections</li>
            <li>Industry-standard data protection practices</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">
            8. Changes to This Policy
          </h2>
          <p>
            We reserve the right to make changes to our privacy policy. You will
            be notified at least two weeks in advance of any changes taking
            effect.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">9. Contact Us</h2>
          <p>
            If you have any questions or concerns about our privacy policy,
            please contact us at hello@homeshotpro.com.
          </p>
        </div>
      </div>
    </div>
  );
}
