import React from "react";
import { useTranslation } from "react-i18next";

const StepIcon = ({ children }) => (
  <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center text-white text-2xl shadow-md">
    {children}
  </div>
);

const Step = ({ number, title, description }) => (
  <div className="flex flex-col items-center text-center">
    <StepIcon>{number}</StepIcon>
    <h3 className="mt-4 text-lg font-semibold text-gray-800">{title}</h3>
    <p className="mt-2 text-sm text-gray-600">{description}</p>
  </div>
);

const SectionSteps = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gradient-to-b from-indigo-50 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <p className="text-base font-semibold leading-7 text-indigo-600 text-center">
          {t("sectionSteps.subtitle")}
        </p>
        <h2 className="text-3xl font-bold text-center text-indigo-700 mb-12">
          {t("sectionSteps.title")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {t("sectionSteps.steps", { returnObjects: true }).map(
            (step, index) => (
              <Step
                key={index}
                number={index + 1}
                title={step.title}
                description={step.description}
              />
            )
          )}
        </div>

        <div className="mt-16 bg-white rounded-lg p-8 flex flex-col md:flex-row justify-between items-center shadow-xl">
          <div className="mb-6 md:mb-0 md:mr-8">
            <h3 className="text-2xl font-bold text-indigo-700 mb-4">
              {t("sectionSteps.includes.title")}
            </h3>
            <ul className="text-gray-700 space-y-2">
              {t("sectionSteps.includes.items", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index} className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2 text-emerald-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="text-center">
            <p className="text-xl text-gray-600">
              {t("sectionSteps.pricing.startingAt")}
            </p>
            <p className="text-5xl font-bold text-indigo-700">
              {t("sectionSteps.pricing.price")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSteps;
